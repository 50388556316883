import React from "react"
import { Link } from "gatsby"
import borrowingpower from "../../assets/images/calc-borrowing-power.svg"
import loancomparison from "../../assets/images/calc-loan-comparison.svg"
import propertybuyingcost from "../../assets/images/calc-property-buying-cost.svg"
import rightArrow from "../../assets/images/right-arrow.svg"

const TopCalculators = ({ sectionTitle, showMore }) => {
  return (
    <section className="calculator-area">
      <div className="container">
        <div className="section-title">
          <h2>{sectionTitle}</h2>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6">
            <Link
              to="/calculators/borrowing-power-calculator"
              className="highlight-calculator"
            >
              <div className="icon">
                <img src={borrowingpower} alt="icon" />
              </div>
              <h3>Borrowing Power Calculator</h3>
              <p>Use your details to estimate how much you can borrow.</p>
            </Link>
          </div>

          <div className="col-lg-4 col-md-6">
            <Link
              to="/calculators/loan-comparison-calculator"
              className="highlight-calculator"
            >
              <div className="icon">
                <img src={loancomparison} alt="icon" />
              </div>
              <h3>Loan Comparison Calculator</h3>
              <p>Adjust rates and terms to compare different home loans.</p>
            </Link>
          </div>

          <div className="col-lg-4 col-md-6">
            <Link
              to="/calculators/property-buying-cost-calculator"
              className="highlight-calculator"
            >
              <div className="icon">
                <img src={propertybuyingcost} alt="icon" />
              </div>
              <h3>Property Buying Cost Calculator</h3>
              <p>See what buying will cost you on top of the purchase price.</p>
            </Link>
          </div>

          {showMore ? (
            <div className="col-12">
              <Link to="/calculators" className="more-arrow mt-3">
                More Calculators <img src={rightArrow} alt="arrow icon" />
              </Link>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </section>
  )
}

export default TopCalculators
